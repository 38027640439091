import './App.css';
import { useState, useEffect } from 'react'
import Login from './Login';
import RegistrationForm from './SignUp';
const LoginButton = (props) => {
    return <button onClick={props.Login} className="choice">LOGIN</button>
}

const SignupButton = (props) => {
    return <button onClick={props.Signup} className="choice">SIGN UP</button>  
}

function App() {

  const [state, setState] = useState('start');
  function Title() {
    useEffect(() => {
      document.title = 'Kinnect 4';
    }, []);
  }
  
  return (
    <>
    <Title />
    <body>
    
    <div className="App">
      <header className="App-header">
        <h1> <a href="/">Kinnect 4</a></h1>

        <div className="box">
        <div className="inner-box">
        {state === 'start' && (
        <><LoginButton Login={() => setState('login')} />
        <SignupButton Signup={() => setState('sign-up')} /></> )}
        {state === 'login' && <Login />}
        {state === 'sign-up' && <RegistrationForm />}
        </div>
        </div>
      </header> 

    </div>
    </body>
    </>
  );
}

export default App;