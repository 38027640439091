import React, {useState} from 'react';
import './App.css'
import Axios from 'axios';

function RegistrationForm() {
    const [username, setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [strerrorMessage, setErrorMessage] = useState('');
    const [strsuccessMessage, setSuccessMessage] = useState('');

    // States for checking the errors
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const handleInputChange = (e) => {
        const {id , value} = e.target;
        if(id === "username"){
            setUsername(value);
        }
        if(id === "password"){
            setPassword(value);
        }
        if(id === "confirmPassword"){
            setConfirmPassword(value);
        }

    }

    function encodeID(s) {
      if (s==='') return '_';
      return s.replace(/[^a-zA-Z0-9.-]/g, function(match) {
          return '_'+match[0].charCodeAt(0).toString(16)+'_';
      });
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        if (encodeID(username) === '' || password === '' || (password !== confirmPassword)) {
          setError(true);
          setSuccessMessage('');
          setErrorMessage('Incorrect fields');
        } else {
          await Axios.post("http://localhost:8081/api/register",
          { user: encodeID(username), password: password })
          .then(response => { 
            if (response.status === 200){
              setSubmitted(true);
              setErrorMessage('');
              setSuccessMessage(`User ${encodeID(username)} successfully registered`);
              setError(false);
            }
        })
          .catch((err => {
            setError(true);
            setSuccessMessage('');
            setErrorMessage(`username ${encodeID(username)} already in use`);
            console.log("not found\n"+ err)}));
        }
      };
      const successMessage = () => {
        return (
          <div
            className="message"
            style={{
              display: submitted ? '' : 'none',color:'green'
            }}>
            <p> {strsuccessMessage}</p>
          </div>
        );
      };
     
      // Showing error message if error is true
      const errorMessage = () => {
        const temp = strerrorMessage;
        return (
          <div
            className="message"
            style={{
              display: error ? '' : 'none', color:'red'
            }}>
            <p>{ temp }</p>
          </div>
        );
      };

    return(
<div className='signup_form'>
    <h2> Sign up</h2>
    <input type="text" id="username" value={username} onChange = {(e) => handleInputChange(e)} placeholder="Username" required={true}/>
    <input type="password" id="password" value={password} onChange = {(e) => handleInputChange(e)} placeholder="Password" required={true}/>
    <input type="password" id="confirmPassword" value={confirmPassword} onChange = {(e) => handleInputChange(e)} required={true} placeholder="Confirm Password"/>
    <button onClick={handleSubmit} type="submit" >Register</button>
    <div className='message'> 
      {errorMessage()}
      {successMessage()}
    </div>
</div>
       
    )       
}

export default RegistrationForm;