import React, { Component, useState } from 'react';
import Chart from 'chart.js/auto';

export default class Dashboard extends Component{
    componentDidMount() {     
    var chartStatus = Chart.getChart("chart"); // <canvas> id
    if (chartStatus !== undefined) {
        chartStatus.destroy();
    }
    var ctx = document.getElementById('chart');
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['09/06/23', '10/06/23', '11/06/23', '12/06/23', '13/06/23', '14/06/23'],
        datasets: [{
            label: 'Win rate',
            backgroundColor:"rgba(255,0,0,5.0)",
            borderColor: "red",
            data: [0.38, 0.39, 0.43, 0.41, 0.28, 0.34],
            borderWidth: 1
        }]
      }
    });
    
}
    render() {
        return (
        <>
         Stats page for <p style={{color :  'yellow'}}> {this.props.user}</p>
        <p> W/R Ratio: {this.props.ratio} <br/>
         Games played: {this.props.games}</p>
        <div className='chart-wrapper'>
        <canvas id="chart"></canvas>
        </div>
        </>
    );
        }
};