import React, { useState } from 'react';
import Axios from 'axios';
import Dashboard from './Dashboard';


function Login() {
    
    
    const [username, setUsername] = useState('');
    const [validUsername, setValidUsername] = useState('');
    const [WRratio, setWRRatio] = useState();
    const [games, setGames] = useState();
    
    const [connected, setConnected] = useState(false);
    
    const [password,setPassword] = useState('');
    const [strerrorMessage, setErrorMessage] = useState('');
    const [strsuccessMessage, setSuccessMessage] = useState('');
    
    // States for checking the errors
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const handleInputChange = (e) => {
        const {id , value} = e.target;
        if(id === "username"){
            setUsername(value);
        }
        if(id === "password"){
            setPassword(value);
        }
        
    }
    function encodeID(s) {
        if (s==='') return '_';
        return s.replace(/[^a-zA-Z0-9.-]/g, function(match) {
            return '_'+match[0].charCodeAt(0).toString(16)+'_';
        });
    }
    
    const handleSubmit = async(e) => {
        setConnected(true);
        e.preventDefault();
        if (encodeID(username) === '' || password === '') {
            setError(true);
            setSuccessMessage('');
            setErrorMessage('Incorrect fields');
        } else {
            await Axios.post("http://localhost:8081/api/connect",
            { user: encodeID(username), password: password })
            .then(async response => { 
                if (response.status === 200){
                    setSubmitted(true);
                    setErrorMessage('');
                    setConnected(true)
                    setValidUsername(encodeID(username))
                    setSuccessMessage('connected');
                    setError(false);
                }
            })
            .catch((err => {
                setError(true);
                setSuccessMessage('');
                setErrorMessage("wrong credentials");
            }));
        }
    };
    const successMessage = () => {
        return (
            <div
            className="message"
            style={{
                display: submitted ? '' : 'none',color:'green'
            }}>
                <p> {strsuccessMessage}</p>
            </div>
            );
        };
        
        // Showing error message if error is true
        const errorMessage = () => {
            const temp = strerrorMessage;
            return (
            <div
                className="message"
                style={{
                display: error ? '' : 'none', color:'red'
                }}>
                <p>{ temp }</p>
            </div>
            );
        };



    if (connected) {

        let jsondata;    
    //     Axios.post("http://localhost:8081/api/retrieve",
    //             { user: validUsername }).then(
    //             function(u){
    //                 setWRRatio(isNaN((u.data[0].wins / u.data[0].games)) ? 0 : (u.data[0].wins / u.data[0].games))
    //                 setGames(u.data[0].games)
    //             } 
    //         ).then(
    //             function(json){
    //             jsondata = json;
    //     }
    //   )                    
        return (<> <Dashboard user={'Demo'} ratio={0.34} games={2837} /> </>)

    }else{
    return (
        <>
        <h2>LOGIN</h2>
            <input type="text" id="username" name="name" value={username} onChange = {(e) => handleInputChange(e)} placeholder="Username" required={true} />
            <input type="password" id="password" name="password" value={password} onChange = {(e) => handleInputChange(e)} placeholder="******" required={true} />
            <button type="submit" className= {"button"} onClick={handleSubmit} value="Submit"> Log in</button>
        <div className='message'> 
            {errorMessage()}
            {successMessage()}
        </div>
        </>
    );
    }
}
export default Login;